import { useState } from "react";
import cn from "classnames";
import { useIntl, FormattedMessage } from "react-intl";
import { MailchimpNewsletterSignupSlice } from "prismicio-types";
import { withConfigure } from "hyam-core/utils/withConfigure";
import { styles as moduleStyles, S01MailchimpNewsletterSignupStyles } from "./styles";
import {
  mailchimpAddListMember,
  MailchimpAddListMemberResponse,
} from "hyam-core/utils/mailchimp";
import {
  useForm as useReactHookForm,
  SubmitHandler,
  SubmitErrorHandler,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import type { z as zType } from "zod";
import { useZodImport } from "hyam-core/hooks/zodDynamicImport";
import { useFormSchemas } from "hyam-prismic/utils/forms";
import {
  HyamPrismicButton,
  HyamPrismicInput,
  HyamPrismicCheckbox,
  HyamPrismicRichText,
  HyamPrismicImage,
} from "hyam-prismic/components";

export type S01MailchimpNewsletterSignupProps = {
  slice: MailchimpNewsletterSignupSlice;
  index: number;
  styles?: Partial<S01MailchimpNewsletterSignupStyles>;
  stylesReplace?: Partial<S01MailchimpNewsletterSignupStyles>;
};

type MailchimpNewsletterSignupProps = S01MailchimpNewsletterSignupProps & {
  z: typeof zType;
  formSchemas: {
    email: zType.ZodString;
    textInput: zType.ZodString;
    checkboxRequired: zType.ZodLiteral<"true">;
  };
};

const MailchimpSignUpNewsletter = (props: MailchimpNewsletterSignupProps) => {
  const styles = props.styles as S01MailchimpNewsletterSignupStyles;
  const { z, formSchemas } = props;
  console.log("== props", props);
  const { formatMessage } = useIntl();

  const [mailchimpResponse, setMailchimpResponse] = useState(
    undefined as MailchimpAddListMemberResponse | undefined,
  );

  const validationSchema = z.object({
    email: formSchemas.email,
    firstname: formSchemas.textInput,
    lastname: formSchemas.textInput,
    data_processing_consent: formSchemas.checkboxRequired,
  });

  type ValidationSchema = zType.infer<typeof validationSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useReactHookForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  });

  const hookFormOnSubmit: SubmitHandler<ValidationSchema> = async (data) => {
    const response = await mailchimpAddListMember({
      email: data.email as string,
      firstName: data.firstname as string,
      lastName: data.lastname as string,
      dataProcessingConsent: data.data_processing_consent as string,
    });
    setMailchimpResponse(response);
    console.log("___ hookFormOnSubmit", response);
  };
  const hookFormOnError: SubmitErrorHandler<ValidationSchema> = (data) => {
    console.log("___ hookFormOnError", data);
  };

  if (mailchimpResponse?.status === "success") {
    return (
      <div className={styles.success_container}>
        <FormattedMessage id="newsletter_submit_success_message" />
      </div>
    );
  }

  return (
    <>
      <div className="mx-auto w-fit mt-10 lg:mt-28">
        <HyamPrismicRichText
          field={props.slice.primary.headline}
          styles={{ heading4: "text-[64px] lg:text-[95px] leading-[60px]" }}
        ></HyamPrismicRichText>
      </div>
      <HyamPrismicImage
        priority
        styles={{ container: "w-full  max-w-3xl mx-auto", image: "w-full" }}
        field={props.slice.primary.image}
        sizesByBreakpoint={{
          sm: 1,
          lg: 1,
          xl: 1,
        }}
      />
      <form
        className={styles.container}
        onSubmit={handleSubmit(hookFormOnSubmit, hookFormOnError)}
      >
        <HyamPrismicInput
          register={register("firstname")}
          error={errors.firstname}
          label={formatMessage({ id: "form_first_name_label" })}
          placeholder={formatMessage({ id: "form_first_name_placeholder" })}
          styles={{ container: styles.input_container }}
        />

        <HyamPrismicInput
          register={register("lastname")}
          error={errors.lastname}
          label={formatMessage({ id: "form_last_name_label" })}
          placeholder={formatMessage({ id: "form_last_name_placeholder" })}
          styles={{ container: styles.input_container }}
        />

        <HyamPrismicInput
          register={register("email")}
          error={errors.email}
          label={formatMessage({ id: "form_email_label" })}
          placeholder={formatMessage({ id: "form_email_placeholder" })}
          styles={{ container: styles.input_container }}
        />

        {/* Data Processing Checkbox */}
        <div className="flex pt-9 pb-6 lg:pt-8 lg:pb-6 gap-7 relative pr-4">
          <HyamPrismicCheckbox
            id="data_processing_consent"
            value="true"
            register={register("data_processing_consent")}
          />
          <p className="hyam-text-caption mt-0">
            <FormattedMessage id="newsletter_data_processing_message" />
          </p>
          {errors.data_processing_consent && (
            <div className="absolute bottom-0">
              <p className="hyam-text-caption mt-0 text-hyam-alert-red">
                {errors.data_processing_consent.message}
              </p>
            </div>
          )}
        </div>

        {mailchimpResponse?.status === "error" && (
          <p className={cn(styles.server_error_message, "pb-3")}>
            <FormattedMessage id={mailchimpResponse.messageId} />
          </p>
        )}
        <div className="mb-10">
          <HyamPrismicButton
            styles={{
              prismic_link_wrapper: "!w-full",
              container_contained:
                "!mt-0 flex justify-center py-2 hyam-text-medium bg-black text-white !w-full",
            }}
            disabled={isSubmitting}
            buttonType="submit"
          >
            <FormattedMessage id={"newsletter_submit_button"} />
          </HyamPrismicButton>
        </div>
      </form>

      <div className="max-w-3xl mx-auto border-black/20 border-t-[1px]">
        <p className="hyam-text-caption mt-4 mx-auto w-fit">
          Copyright © 2024 hy.am studios, All rights reserved.
        </p>
      </div>
    </>
  );
};

const _S01MailchimpNewsletterSignup = (props: S01MailchimpNewsletterSignupProps) => {
  // need to dynamically import zod from a separate component for the hooks in
  // <MailchimpSignupResponse/> to be called equally on every render
  const { z } = useZodImport();
  const formSchemas = useFormSchemas();
  if (!formSchemas || !z) return null;

  return <MailchimpSignUpNewsletter {...props} z={z} formSchemas={formSchemas} />;
};

export const S01MailchimpNewsletterSignup = withConfigure<
  S01MailchimpNewsletterSignupProps,
  S01MailchimpNewsletterSignupStyles
>({
  componentName: "S01MailchimpNewsletterSignup",
  Component: _S01MailchimpNewsletterSignup,
  moduleStyles,
});
