/**
 * Test that integration is working correctly.
 */
export const mailchimpTest = async () => {
  const response = await fetch("/api/mailchimp/ping", {
    method: "POST",
  });
  const responseJson = await response.json();

  return responseJson;
};

export type MailchimpAddListMemberBody = {
  email: string;
  firstName: string;
  lastName: string;
  dataProcessingConsent: string;
};

export type MailchimpAddListMemberResponse = {
  status: "success" | "error";
  message: string;
  messageId: "mailchimp_error_user_already_exists" | "mailchimp_error_unknown";
};

/**
 * Add member (new subscriber) to list.
 */
export const mailchimpAddListMember = async (body: MailchimpAddListMemberBody) => {
  const response = await fetch("/api/mailchimp/addListMember", {
    method: "POST",
    body: JSON.stringify(body),
  });
  const responseJson = await response.json();

  return responseJson;
};
