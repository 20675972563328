import cn from "classnames";

export const styles = {
  container: "relative flex flex-col py-2",
  container_error: "flex flex-col pb-0",

  label: cn(
    "top-1/2 -translate-y-1/2 absolute left-2 z-10",
    "peer-focus-within:top-0 peer-focus-within:translate-y-0 peer-focus-within:text-[11px]",
    "peer-[:not(:placeholder-shown)]:top-0 peer-[:not(:placeholder-shown)]:translate-y-0",
    "peer-[:not(:placeholder-shown)]:text-[11px]",
    "hyam-text-caption mb-2 uppercase bg-white px-[5px]",
    "duration-150 transition-all ease-out",
    "pointer-events-none",
  ),
  label_error:
    "text-hyam-alert-red top-0 translate-y-0 text-[11px]",

  input: cn(
    "peer",
    "focus:ring-0 focus:border-hyam-black",
    "border-[1px] border-hyam-black",
    "px-4 py-2.5",
    "placeholder-hyam-dark-grey hyam-text-small mb-0",
  ),

  input_error: cn(
    "border-hyam-alert-red focus:border-hyam-alert-red mb-0",
    "placeholder-hyam-alert-red text-hyam-alert-red",
  ),

  error_message:
    "text-hyam-alert-red hyam-text-caption mb-2 mt-2",
};

export type HyamPrismicInputStyles =
  typeof styles;
