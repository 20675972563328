import { useState, useEffect } from "react";

type ZodImport = {
  z: typeof import("node_modules/zod/lib/external");
};

const zodNullImport = {
  z: null,
};

type ZodNullImport = typeof zodNullImport;

const importZod = async () => {
  const zod: ZodImport = await import("zod");

  return zod;
};

export const useZodImport = () => {
  const [zod, setZod] = useState(zodNullImport as ZodImport | ZodNullImport);

  useEffect(() => {
    importZod().then((value) => {
      setZod(value);
    });
  }, []);

  return zod;
};
