import cn from "classnames";

export const styles = {
  container: "",
  input: cn(
    "h-4 w-4 -translate-y-1",
    "focus:ring-0 focus:border-hyam-black",
    "border border-hyam-black !rounded-none",
    "text-hyam-black",
    "cursor-pointer",
  ),
  input_error:
    "border-hyam-alert-red focus:border-hyam-alert-red",
};

export type HyamPrismicCheckboxStyles =
  typeof styles;
