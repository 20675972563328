import { withConfigure } from "hyam-core/utils/withConfigure";
import { UseFormRegisterReturn, FieldError } from "react-hook-form";
import { styles as moduleStyles, HyamPrismicCheckboxStyles } from "./styles";
import { classNamesWithMerge as cn } from "hyam-core/utils/tailwindMerge";

export type HyamPrismicCheckboxProps = {
  styles?: Partial<HyamPrismicCheckboxStyles>;
  stylesReplace?: Partial<HyamPrismicCheckboxStyles>;
  error?: FieldError;
  register?: UseFormRegisterReturn;
  id: string;
  value: string;
};

const _HyamPrismicCheckbox = (props: HyamPrismicCheckboxProps) => {
  const styles = props.styles as HyamPrismicCheckboxStyles;
  const { id, value, register, error } = props;

  return (
    <div className={styles.container}>
      <input
        className={cn(styles.input, { [styles.input_error]: error })}
        type="checkbox"
        id={id}
        name={id}
        value={value}
        {...register}
      />
    </div>
  );
};

export const HyamPrismicCheckbox = withConfigure<
  HyamPrismicCheckboxProps,
  HyamPrismicCheckboxStyles
>({
  componentName: "HyamPrismicCheckbox",
  Component: _HyamPrismicCheckbox,
  moduleStyles,
});
