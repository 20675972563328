import _ from "lodash";
import { useRef } from "react";
import { withConfigure } from "hyam-core/utils/withConfigure";
import { motion, useScroll, useTransform } from "framer-motion";
import { styles as moduleStyles, HyamPrismicImageStyles } from "./styles";
import { PrismicNextImage, PrismicNextImageProps } from "@prismicio/next";
import { tailwindScreenSizesStrings as screens } from "hyam-core/utils/getTailwindTheme";

export type HyamPrismicImageProps = PrismicNextImageProps & {
  styles?: Partial<HyamPrismicImageStyles>;
  stylesReplace?: Partial<HyamPrismicImageStyles>;
  zoomFinal?: number;
  sizesByBreakpoint: SizesByBreakpoint;
  animationProps?: any;
  disableAnimation?: boolean;
};

/** Sizes from 0.01 to 1 corresponding to 1vw to 100vw */
type SizesByBreakpoint = {
  sm: number;
  lg: number;
  xl: number;
};

const validateSizes = (sizes: SizesByBreakpoint) => {
  if (_.isEmpty(sizes)) {
    throw new Error("HyamPrismicImage image sizes is empty");
  }

  _.each(sizes, (size, breakpoint) => {
    if (size < 0.01 || size > 1) {
      throw new Error(
        "HyamPrismicImage image sizes should be between 0 and 1. Got: " +
          breakpoint +
          " = " +
          size,
      );
    }
  });
};

export const getSizes = (sizes: SizesByBreakpoint) => {
  validateSizes(sizes);

  const nextImageSizesArray = _.map(
    sizes,
    (size: number, breakpoint: "sm" | "lg" | "xl") => {
      const maxWidth = screens[breakpoint];
      const vw = Math.round(size * 100) + "vw";

      if (breakpoint === "xl") return vw;

      return `(max-width: ${maxWidth}) ${vw}`;
    },
  );

  // example: "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
  const nextImageSizesString = nextImageSizesArray.join(", ");

  return nextImageSizesString;
};

const _HyamPrismicImage = (props: HyamPrismicImageProps) => {
  const ref = useRef(null);
  const {
    zoomFinal = 1.08,
    styles: _styles,
    sizesByBreakpoint,
    disableAnimation = true,
    ...nextImageProps
  } = props;

  const styles = _styles as HyamPrismicImageStyles;

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end end"],
  });
  const scale = useTransform(scrollYProgress, [0, 1], [1, zoomFinal]);

  const sizes = getSizes(sizesByBreakpoint);

  return (
    <motion.div
      ref={ref}
      className={styles.container}
      style={disableAnimation ? undefined : { scaleX: scale, scaleY: scale }}
    >
      <PrismicNextImage
        // NOTE: we can set imgix dpr for retina 2x resolution:
        //       https://docs.imgix.com/apis/rendering/pixel-density/dpr
        fill
        alt={props?.alt}
        className={styles.image}
        {...nextImageProps}
        field={props.field}
        sizes={sizes}
      />
    </motion.div>
  );
};

export const HyamPrismicImage = withConfigure<
  HyamPrismicImageProps,
  HyamPrismicImageStyles
>({
  componentName: "HyamPrismicImage",
  Component: _HyamPrismicImage,
  moduleStyles,
});
