import { useIntl } from "react-intl";
import { useZodImport } from "hyam-core/hooks/zodDynamicImport";

export const useFormSchemas = () => {
  const { formatMessage } = useIntl();
  const errorGenericRequired = formatMessage({ id: "form_error_required_generic" });
  const { z } = useZodImport();

  if (!z) return null;

  const schemas = {
    email: z
      .string()
      .min(1, { message: formatMessage({ id: "form_error_required_email" }) })
      .email({
        message: formatMessage({ id: "form_error_invalid_email" }),
      }),
    toggle: z.boolean(),
    textInput: z.string().min(1, { message: errorGenericRequired }),
    textArea: z.string().min(1, { message: errorGenericRequired }),
    radioButtons: z.string({
      required_error: errorGenericRequired,
      invalid_type_error: errorGenericRequired,
    }),
    numericSlider: z.string().min(1, { message: errorGenericRequired }),
    checkboxRequired: z.literal("true", {
      errorMap: () => ({ message: formatMessage({ id: "form_error_required_generic" }) }),
    }),
  };

  return schemas;
};
