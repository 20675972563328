import { useState } from "react";
import { UseFormRegisterReturn, FieldError, ChangeHandler } from "react-hook-form";
import { mergeStyles } from "hyam-core/utils";
import { styles as moduleStyles, HyamPrismicInputStyles } from "./styles";
import { classNamesWithMerge as cn } from "hyam-core/utils/tailwindMerge";

export type HyamPrismicInputProps = {
  styles?: Partial<HyamPrismicInputStyles>;
  label?: string | null;
  placeholder?: string | null;
  error?: FieldError;
  register?: UseFormRegisterReturn;
};

export const HyamPrismicInput = (props: HyamPrismicInputProps) => {
  const { styles: propsStyles = {}, label, register, error, placeholder } = props;
  const styles = mergeStyles(moduleStyles, propsStyles);
  const [value, setValue] = useState("");

  const onChange: ChangeHandler = async (event) => {
    if (!register?.onChange) return;

    setValue(event?.target?.value || "");

    return await register.onChange(event);
  };

  const showLabel = !!label && !!value;

  return (
    <div
      className={cn(styles.container, {
        [styles.container_error]: error,
      })}
    >
      <input
        className={cn(styles.input, { [styles.input_error]: error })}
        type="text"
        placeholder={""}
        {...register}
        onChange={onChange}
      />
      <label
        data-not-empty={showLabel}
        data-placeholder={placeholder}
        className={cn(styles.label, {
          [styles.label_error]: error,
        })}
      >
        {label}
      </label>
      {error && <div className={styles.error_message}>{error.message}</div>}
    </div>
  );
};
