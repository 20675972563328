export const styles = {
  container:
    "mt-10 lg:mt-20 mb-0 max-w-3xl mx-auto",
  input_container: "",
  legal_disclaimer_container: "",
  legal_disclaimer: "",
  server_error_message: "text-hyam-alert-red",
  success_container: "pt-20 hyam-text-h5 mt-0",
};

export type S01MailchimpNewsletterSignupStyles =
  typeof styles;
